
import { Menu as IconMenu, Message, Setting } from "@element-plus/icons-vue";
import { ElLoading } from 'element-plus';
import { ElMessage, ElMessageBox } from 'element-plus';
import { ref, reactive, getCurrentInstance } from 'vue';
import { FormInstance, FormRules } from 'element-plus';


export default {
    data() {
      return {
        tableData: [],
        search: '',
        multipleSelection:[],
        dialogFormVisible:false,
        dialogFormVisibleEdit:false,
        form:{spbh:'',spm:'',splb:'',dj:'',zk:'',sl:'',sjrq:'',scrq:'',spzt:'',spms:'',spbz:'',ldtp:''},
        formEdit:{spbh:'',spm:'',splb:'',dj:'',zk:'',sl:'',sjrq:'',scrq:'',spzt:'',spms:'',spbz:'',ldtp:''},
        formLabelWidth : '140px',
        rules: {
          spm: [
            { required: true, message: '请输入', trigger: 'blur' },
            { min: 1,max:99999, message: '请输入', trigger: 'blur' }
          ],
          mm: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 1,max:10, message: '请输入3~10位的密码', trigger: 'blur' }
          ],
  //         region: [
  //         {
  //           required: true,
  //           message: '请选择',
  //           trigger: 'change',
  //         },
         
  //         ],
  //          date1: [
  //   {
  //     type: 'date',
  //     required: true,
  //     message: 'Please pick a date',
  //     trigger: 'change',
  //   },
  // ],
  // date2: [
  //   {
  //     type: 'date',
  //     required: true,
  //     message: 'Please pick a date',
  //     trigger: 'change',
  //   },
  // ]
        }

      }
    },
    created() {
    this.load();
  },
    methods: {


      stateFormat2(row, column) {
    if (row.splb == '1') {
      return '喷气式航模'
    } else if (row.splb == '2') {
      return '螺旋桨式航模'
    } 
    else if (row.splb == '3') {
      return '老式航模'
    } 
    },

          stateFormat3(row, column) {
    if (row.spzt == '1') {
      return '可售'
    } else  {
      return '不可售'
    } 
    },

      load(){
        this.axios
      .get("/chazhaosp")
      .then((response) => {
        if (response) {
          this.tableData = response.data.data;
          console.log(response.data.data)
        }
      })
      .catch((error) => {});
      },



    searchsp(){
       const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })

        this.axios
      .get("/chazhaosp?spm="+this.search)
      .then((response) => {
        if (response) {
          this.tableData = response.data.data;
          console.log(response.data.data)
        }
      })
      .catch((error) => {});
      
      setTimeout(() => {
          loading.close();
        }, 1000)
      },

    handleEdit(index, row) {
        console.log(index, row);
        this.dialogFormVisibleEdit=true;
        this.formEdit.spbh=row.spbh;
        this.formEdit.spm=row.spm;
        this.formEdit.splb=row.splb;
        this.formEdit.dj=row.dj;
        this.formEdit.zk=row.zk;
        this.formEdit.sl=row.sl;
        this.formEdit.sjrq=row.sjrq;
        this.formEdit.scrq=row.scrq;
        this.formEdit.spzt=row.spzt;
        this.formEdit.spms=row.spms;
        this.formEdit.spbz=row.spbz;
        this.formEdit.ldtp=row.ldtp;
  
        
    },
    handleEditUptp(ruleFormsEdittp){
      const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
    })
      this.$refs[ruleFormsEdittp].validate((valid) => {
          if (valid) {
                this.axios.post('/xiugaisp',this.formEdit)
        .then((response) => {
            console.log(response+"sdsds  "+response.data);
          if (response.data==1) {
            this.dialogFormVisibleEdit=false;
            this.$nextTick(() => {
                this.load();
            });
          } else if(response.data==2){
            alert("该商品名已存在");
           }
          else{
            alert("修改失败");
          }
      })
      .catch((error) => {});
            
          } else {
            console.log('error submit!!');
            return false;
          }
    })
        

        setTimeout(() => {
          loading.close();
          this.dialogFormVisible =false;
        }, 1000)

      },
      submitForm(formName) {
        
        dialogFormVisible = false;
      },
    

    //删除
    handleDelete(index, row) {
        console.log(index);
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',

        })
        this.axios
          .get("/shanchusp?ids="+row.spbh)
          .then((response) => {
            if (response.data==1) {
            this.$nextTick(() => {
            this.load();
          });
        }else{
          alert("删除失败");
        }
      })
      .catch((error) => {});

      setTimeout(() => {
          loading.close();
        }, 1000)
    },
    handleSelectionChange(val) {
        this.multipleSelection.length = 0;
        val.forEach((val, index) => {
            this.multipleSelection.push(val.spbh);
        })
        console.log('多选选中的行', this.multipleSelection);
    },
      
    //多选删除  
    handleDeletes() {
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.axios
          .get("/shanchusp?ids="+this.multipleSelection)
          .then((response) => {
            if (response.data==1) {
              
            this.$nextTick(() => {
            this.load();
          });
        }else{
          alert("删除失败");
        }
      })
      .catch((error) => {});

      setTimeout(() => {
          loading.close();
        }, 1000)
    },

    open () {
        ElMessageBox.confirm(
            'proxy will permanently delete the file. Continue?',
            'Warning',
            {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning',
                draggable:true,
            }
        )
        .then(() => {
            this.handleDeletes();
            this.load();
            ElMessage({
                type: 'success',
                message: 'Delete completed',
            })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: 'Delete canceled',
            })
        })
  },

  spAdd(ruleForms){
    const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
    })

    
      this.$refs[ruleForms].validate((valid) => {
          if (valid) { console.log(this.form);
                this.axios.post('/zengjiasp',this.form)
        .then((response) => {
           
          if (response.data==1) {
            this.$nextTick(() => {
                this.load();
            });
          } else if(response.data==2){
            alert("该商品名已存在");
           }
          else{
            alert("添加失败");
          }
      })
      .catch((error) => {});
            
          } else {
            console.log('error submit!!');
            return false;
          }
    })
        

        setTimeout(() => {
          loading.close();
          this.dialogFormVisible =false;
        }, 1000)

      },
      submitForm(formName) {
        
        dialogFormVisible = false;
      },
    },

}
