
import { Menu as IconMenu, Message, Setting } from "@element-plus/icons-vue";
import { ElLoading } from 'element-plus';
import { ElMessage, ElMessageBox } from 'element-plus';
import { ref, reactive, getCurrentInstance } from 'vue';
// import { FormInstance, FormRules } from 'element-plus';

export default {
    data() {
      return {
        tableData: [],
        search: '',
        multipleSelection:[],
        dialogFormVisible:false,
        dialogFormVisibleEdit:false,
        form:{spbh:'',tplj:'',ztp:''},
        formEdit:{bh:'',spbh:'',tplj:'',ztp:''},
        formLabelWidth : '140px',
        rules: {
          spbh: [
            { required: true, message: '请输入', trigger: 'blur' },
            { min: 1,max:99999, message: '请输入', trigger: 'blur' }
          ],
          mm: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 1,max:10, message: '请输入3~10位的密码', trigger: 'blur' }
          ],
        //   region: [
        //   {
        //     required: true,
        //     message: '请选择',
        //     trigger: 'change',
        //   },
         
        //   ],
        }

      }
    },
    created() {
    this.load();
  },
    methods: {
      load(){
        this.axios
      .get("/sptpcz?spbh= ")
      .then((response) => {
        if (response) {
          this.tableData = response.data.data;
          console.log(response.data.data)
        }
      })
      .catch((error) => {});
      },

      stateFormat(row, column) {
    if (row.ztp == '1') {
      return '是'
    } else  {
      return '否'
    } 
    },

    searchUser(){
       const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',

        })

        this.axios
      .get("/sptpcz?spbh="+this.search)
      .then((response) => {
        if (response) {
          this.tableData = response.data.data;
          console.log(response.data.data)
        }
      })
      .catch((error) => {});
      
      setTimeout(() => {
          loading.close();
        }, 1000)
      },

    handleEdit(index, row) {
        console.log(index, row);
        this.dialogFormVisibleEdit=true;
        this.formEdit.bh=row.bh;
        this.formEdit.spbh=row.spbh;
        this.formEdit.tplj=row.tplj;
        this.formEdit.ztp=row.ztp;
        
    },
    handleEditUp(ruleFormsEdit){
      const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
    })
      this.$refs[ruleFormsEdit].validate((valid) => {
          if (valid) {
                this.axios.post('/xiugaisptp',this.formEdit)
        .then((response) => {
            console.log(response+"sdsds");
          if (response.data==1) {
            this.dialogFormVisibleEdit=false;
            this.$nextTick(() => {
                this.load();
            });
          } else if(response.data==2){
            alert("该用户名已存在");
           }
          else{
            alert("修改失败");
          }
      })
      .catch((error) => {});
            
          } else {
            console.log('error submit!!');
            return false;
          }
    })
        

        setTimeout(() => {
          loading.close();
          this.dialogFormVisible =false;
        }, 1000)

      },
      submitForm(formName) {
        
        dialogFormVisible = false;
      },
    

    //删除
    handleDelete(index, row) {
        console.log(index);
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',

        })
        this.axios
          .get("/shanchusptp?ids="+row.bh)
          .then((response) => {
            if (response.data==1) {
            this.$nextTick(() => {
            this.load();
          });
        }else{
          alert("删除失败");
        }
      })
      .catch((error) => {});

      setTimeout(() => {
          loading.close();
        }, 1000)
    },
    handleSelectionChange(val) {
        this.multipleSelection.length = 0;
        val.forEach((val, index) => {
            this.multipleSelection.push(val.bh);
        })
        console.log('多选选中的行', this.multipleSelection);
    },
      
    //多选删除  
    handleDeletes() {
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.axios
          .get("/shanchusptp?ids="+this.multipleSelection)
          .then((response) => {
            if (response.data==1) {
            this.$nextTick(() => {
            this.load();
          });
        }else{
          alert("删除失败");
        }
      })
      .catch((error) => {});

      setTimeout(() => {
          loading.close();
        }, 1000)
    },

    open () {
        ElMessageBox.confirm(
            'proxy will permanently delete the file. Continue?',
            'Warning',
            {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning',
                draggable:true,
            }
        )
        .then(() => {
            this.handleDeletes();
            this.load();
            ElMessage({
                type: 'success',
                message: 'Delete completed',
            })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: 'Delete canceled',
            })
        })
  },

  sptpAdd(ruleForms){
    const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
    })


      this.$refs[ruleForms].validate((valid) => {
          if (valid) {
                this.axios.post('/zengjiasptp',this.form)
        .then((response) => {
            console.log(response+"sdsds");
          if (response.data>0) {
            this.$nextTick(() => {
                this.load();
            });
          }
          else{
            alert("添加失败");
          }
      })
      .catch((error) => {});
            
          } else {
            console.log('error submit!!');
            return false;
          }
    })
        

        setTimeout(() => {
          loading.close();
          this.dialogFormVisible =false;
        }, 1000)

      },
      submitForm(formName) {
        
        dialogFormVisible = false;
      },
    },

}
