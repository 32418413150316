<template>
  <el-main height="100%">
    <el-scrollbar height="100%">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="ID" prop="gkbh" width="150" />
        <el-table-column label="用户名" prop="yhm" width="150" />
        <el-table-column label="注册时间" prop="time" width="250" />

        <el-table-column align="right">
          <template #header>
            <el-button
              size="small"
              type="primary"
              @click="this.dialogFormVisible = true"
              >add</el-button
            >

            <el-button size="small" type="danger" @click="open"
              >Delete</el-button
            >

            <el-input
              v-model="search"
              clearable
              type="text"
              placeholder="请输入用户名"
              style="width: 150px; margin-left: 10px"
            ></el-input>
            <el-button @click="searchUser" type="primary" style="margin: 0 5px">
              搜索
            </el-button>
          </template>

          <template #default="scope">
            <el-button size="small" @click="handleEdit(scope.$index, scope.row)"
              >Edit</el-button
            >
            <el-popconfirm
              title="Are you sure to delete this?"
              @confirm="handleDelete(scope.$index, scope.row)"
            >
              <template #reference>
                <el-button size="small" type="danger">Delete</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog v-model="dialogFormVisible" title="添加用户信息">
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <el-form-item label="用户名" :label-width="formLabelWidth" prop="yhm">
            <el-input v-model="form.yhm" autocomplete="off" />
          </el-form-item>
          <el-form-item label="密码" :label-width="formLabelWidth" prop="mm">
            <el-input v-model="form.mm" autocomplete="off" />
          </el-form-item>
          <el-form-item
            label="管理员"
            :label-width="formLabelWidth"
            prop="region"
          >
            <el-select v-model="form.admin" placeholder="是否为管理员">
              <el-option label="是" value="1" />
              <el-option label="否" value="0" />
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogFormVisible = false">Cancel</el-button>
            <el-button type="primary" @click="userAdd('ruleForm')"
              >Confirm</el-button
            >
          </span>
        </template>
      </el-dialog>

      <el-dialog v-model="dialogFormVisibleEdit" title="修改用户信息">
        <el-form :model="form" :rules="rules" ref="ruleFormEdit">
          <el-form-item label="ID" :label-width="formLabelWidth">
            <el-input v-model="formEdit.gkbh" autocomplete="off" disabled />
          </el-form-item>
          <el-form-item label="用户名" :label-width="formLabelWidth">
            <el-input v-model="formEdit.yhm" autocomplete="off" />
          </el-form-item>
          <el-form-item
            label="管理员"
            :label-width="formLabelWidth"
            prop="region"
          >
            <el-select v-model="formEdit.admin" placeholder="是否为管理员">
              <el-option label="是" value="1" />
              <el-option label="否" value="0" />
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogFormVisibleEdit = false">Cancel</el-button>
            <el-button type="primary" @click="handleEditUp('ruleFormEdit')"
              >Confirm</el-button
            >
          </span>
        </template>
      </el-dialog>
    </el-scrollbar>
  </el-main>
</template>

<script>
import { Menu as IconMenu, Message, Setting } from "@element-plus/icons-vue";
import { ElLoading } from 'element-plus';
import { ElMessage, ElMessageBox } from 'element-plus';
import { ref, reactive, getCurrentInstance } from 'vue'

export default {
    data() {
      return {
        tableData: [],
        search: '',
        multipleSelection:[],
        dialogFormVisible:false,
        dialogFormVisibleEdit:false,
        form:{yhm:'',mm:'',admin:''},
        formEdit:{yhm:'',gkbh:'',admin:''},
        formLabelWidth : '140px',
        rules: {
          yhm: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 1,max:99999, message: '请输入用户名', trigger: 'blur' }
          ],
          mm: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 1,max:10, message: '请输入3~10位的密码', trigger: 'blur' }
          ],
        }

      }
    },
    created() {
    this.load();
  },
    methods: {
      load(){
        this.axios
      .get("/userxinxi")
      .then((response) => {
        if (response) {
          this.tableData = response.data.data;
          console.log(response.data.data)
        }
      })
      .catch((error) => {});
      console.log("ssss");
      },

      stateFormat(row, column) {
    if (row.admin == '0') {
      return '是'
    } else  {
      return '否'
    } 
    },

    searchUser(){
       const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',

        })

        this.axios
      .get("/userxinxi?name="+this.search)
      .then((response) => {
        if (response) {
          this.tableData = response.data.data;
          console.log(response.data.data)
        }
      })
      .catch((error) => {});
      
      setTimeout(() => {
          loading.close();
        }, 1000)
      },

    handleEdit(index, row) {
        console.log(index, row);
        this.dialogFormVisibleEdit=true;
        this.formEdit.yhm=row.yhm;
        this.formEdit.gkbh=row.gkbh;
        
    },
    handleEditUp(ruleFormsEdit){
      const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
    })
      this.$refs[ruleFormsEdit].validate((valid) => {
          if (valid) {
                this.axios.post('/xg',this.formEdit)
        .then((response) => {
            console.log(response+"sdsds");
          if (response.data==1) {
            this.dialogFormVisibleEdit=false;
            this.$nextTick(() => {
                this.load();
            });
          } else if(response.data==2){
            alert("该用户名已存在");
           }
          else{
            alert("修改失败");
          }
      })
      .catch((error) => {});
            
          } else {
            console.log('error submit!!');
            return false;
          }
    })
        

        setTimeout(() => {
          loading.close();
          this.dialogFormVisible =false;
        }, 1000)

      },
      submitForm(formName) {
        
        dialogFormVisible = false;
      },
    

    //删除
    handleDelete(index, row) {
        console.log(index);
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',

        })
        this.axios
          .get("/sc?ids="+row.gkbh)
          .then((response) => {
            if (response.data==1) {
            this.$nextTick(() => {
            this.load();
          });
        }else{
          alert("删除失败");
        }
      })
      .catch((error) => {});

      setTimeout(() => {
          loading.close();
        }, 1000)
    },
    handleSelectionChange(val) {
        this.multipleSelection.length = 0;
        val.forEach((val, index) => {
            this.multipleSelection.push(val.gkbh);
        })
        console.log('多选选中的行', this.multipleSelection);
    },
      
    //多选删除  
    handleDeletes() {
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.axios
          .get("/sc?ids="+this.multipleSelection)
          .then((response) => {
            if (response.data==1) {
            this.$nextTick(() => {
            this.load();
          });
        }else{
          alert("删除失败");
        }
      })
      .catch((error) => {});

      setTimeout(() => {
          loading.close();
        }, 1000)
    },

    open () {
        ElMessageBox.confirm(
            'proxy will permanently delete the file. Continue?',
            'Warning',
            {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning',
                draggable:true,
            }
        )
        .then(() => {
            this.handleDeletes();
            this.load();
            ElMessage({
                type: 'success',
                message: 'Delete completed',
            })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: 'Delete canceled',
            })
        })
  },

  userAdd(ruleForms){
    const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
    })


      this.$refs[ruleForms].validate((valid) => {
          if (valid) {
                this.axios.post('/zengjia',this.form)
        .then((response) => {
            console.log(response+"sdsds");
          if (response.data==1) {
            this.$nextTick(() => {
                this.load();
            });
          } else if(response.data==2){
            alert("该用户名已存在");
           }
          else{
            alert("添加失败");
          }
      })
      .catch((error) => {});
            
          } else {
            console.log('error submit!!');
            return false;
          }
    })
        

        setTimeout(() => {
          loading.close();
          this.dialogFormVisible =false;
        }, 1000)

      },
      submitForm(formName) {
        
        dialogFormVisible = false;
      },
    },

}
</script>

<style scoped>
.scrollbar-demo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
}

.layout-container-demo .el-main {
  padding: 0;
}

.mysearch {
  width: 200px;
  margin-left: 20px;
}
</style>
