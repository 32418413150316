import { createStore } from 'vuex'

export default createStore({
  state: {
    seaching:'',
    shopgoods:[

    ]
    ,
    jiagemin:'',
    jiagemax:'',
    yhbh:"",
    yhm:""
  },
  getters: {
  },
  mutations: {
    seaching:function(state,payload){
      state.seaching=payload;
    },
    shopgoods:function(state,payload){
      state.shopgoods.push(payload);
    },
    jiagemin:function(state,payload){
      state.jiagemin=payload;
    },
    jiagemax:function(state,payload){
      state.jiagemax=payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
