<template>
  <div class="thebanners">
    <div id="banner_box">
      <div id="banner">
        <ul id="imgul">
          <li v-for="a in banners" class="imgli">
            <img v-bind:src="a.ldtp"  id="bannermain"/>
          </li>
        </ul>
        <div>
          <a id="btn_left" onclick="left()"
            ><img id="btn_left2" src="../../../static/images/prev1.png"
          /></a>
          <a id="btn_right" onclick="right()"
            ><img id="btn_right2" src="../../../static/images/next1.png"
          /></a>
        </div>
        <showthetime></showthetime>
      </div>
    </div>
  </div>
</template>
<script>
import showthetime from "./showthetime.vue";

export default {
  name: "banner_box",
  components: {
    showthetime,
  },
  data() {
    return {
      banners: [],
    };
  },
  created() {
    this.axios
      .get("/banners?spbh= ")
      .then((response) => {
        if (response) {
          this.banners = response.data.data;
          console.log(this.banners);
          this.$nextTick(() => {
            this.bannermain();
          });
        }
      })
      .catch((error) => {});
  },
  methods: {
    go() {
      this.timer = setInterval(() => {
        this.autoPlay();
      }, 4000);
    },
    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    change(index) {
      this.currentIndex = index;
    },
    autoPlay() {
      this.currentIndex++;
      if (this.currentIndex > this.slideList.length - 1) {
        this.currentIndex = 0;
      }
    },
    bannermain() {
      var left = document.getElementById("btn_left");
      var right = document.getElementById("btn_right");
      var imgul = document.getElementById("imgul");
      var imgli = document.getElementById("imgul").getElementsByTagName("li");
      var imgs = document.getElementById("imgul").getElementsByTagName("img");
      var hh = document.body.clientWidth;
      var liwidth = hh;
      var pnglen = imgli.length;
      var rightTimer = null;
      var leftTimer = null;
      var mainTimer = null;
      var flag = true;
      var ll1 = setInterval(function () {
        imgli[0].style.width = document.body.clientWidth + "px";
        imgli[1].style.width = document.body.clientWidth + "px";
        imgli[2].style.width = document.body.clientWidth + "px";
        imgli[3].style.width = document.body.clientWidth + "px";
        imgs[0].style.width = document.body.clientWidth + "px";
        imgs[1].style.width = document.body.clientWidth + "px";
        imgs[2].style.width = document.body.clientWidth + "px";
        imgs[3].style.width = document.body.clientWidth + "px";
        hh = document.body.clientWidth;
      }, 1);

      imgul.style.width = liwidth * pnglen + "px";

      right.onclick = function () {
        if (flag) {
          liwidth = 0;
          clearInterval(rightTimer);
          rightTimer = setInterval(rightImg, 1);
        }
      };

      function rightImg() {
        imgul.style.left = "-" + liwidth + "px";
        liwidth += 5;
        flag = false;
        if (liwidth >= hh) {
          clearInterval(rightTimer);
          imgul.appendChild(imgli[0]);
          imgul.style.left = 0;
          flag = true;
        }
      }

      left.onclick = function () {
        if (flag) {
          clearInterval(leftTimer);
          liwidth = hh;
          leftTimer = setInterval(leftImg, 1);
        }
      };

      function leftImg() {
        if (liwidth <= hh + 2.5 && liwidth >= hh - 2.5)
          imgul.insertBefore(imgli[pnglen - 1], imgli[0]);
        imgul.style.left = "-" + liwidth + "px";
        liwidth -= 5;
        flag = false;
        if (liwidth <= -1) {
          clearInterval(leftTimer);
          imgul.style.left = 0;
          flag = true;
        }
      }
      left.onmouseover = function () {
        clearInterval(mainTimer);
      };
      left.onmouseout = function () {
        mainTimer = setInterval(right.onclick, 5000);
      };
      right.onmouseover = function () {
        clearInterval(mainTimer);
      };
      right.onmouseout = function () {
        mainTimer = setInterval(right.onclick, 5000);
      };
      mainTimer = setInterval(right.onclick, 5000);
    },
  },
};
</script>

<style scoped>
#banner_box {
  width: 100%;
  height: 100%;
  /* position: relative; */
}

#banner {
  width: 100%;
  height: 755px;
  overflow: hidden;
  position: relative;
}

#imgul {
  position: absolute;
  width: 100%;
  height: 100%;
}

#banner li {
  list-style: none;
  float: left;
  width: 50%;
  height: 100%;
}

#banner a{
  z-index: 999;
}

#bannermain {
  /* display: block;*/
  width: 100%;
  height: 100%; 
}

#btn_left {
  width: 40px;
  height: 70px;
  background-repeat: no-repeat;
  background-position: 0 top;
  position: absolute;
  top: 50%;
  left: 10%;
  cursor: pointer;
}

#btn_right {
  width: 40px;
  height: 70px;
  background-repeat: no-repeat;
  background-position: right top;
  position: absolute;
  top: 50%;
  right: 10%;
  cursor: pointer;
}

#btn_right2 {
  width: auto;
  height: auto;
}
#btn_right2 {
  width: auto;
  height: auto;
}
</style>