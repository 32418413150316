



<template>
  <div class="goods">
    <jiage />
    <div v-show="xxs === 0"><h1>抱歉，未能找到您搜素的商品</h1></div>
    <ul>
      <template v-for="(a, zz) in goodimgs" :key="zz">
        <li
          v-show="
            (a.spm.toUpperCase().search(this.$store.state.seaching.toUpperCase()) >
              -1 ||
              $store.state.seaching == '') &&
            (a.dj >= this.$store.state.jiagemin || this.$store.state.jiagemin == '') &&
            (a.dj <= this.$store.state.jiagemax || this.$store.state.jiagemax == '')
          "
        >
          <router-link :to="'/commodityDetails/' + a.spbh"
            ><img v-bind:src="a.tplj" />
            <h1>{{ a.spm }}</h1>
            <br />
            <h3>¥{{ a.dj }}</h3>
            <br />
            <router-link :to="'/commodityDetails/' + a.spbh" class="hhgoods"
              >了解更多
            </router-link>
          </router-link>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import jiage from "../../components/module/jiage.vue";
export default {
  name: "goods",
  components: {
    jiage,
  },
  data() {
    return {
      // sp: this.$store.state.goodsdata,
      goodimgs: [],
      xxs: 1,
      uuuss: 1,
      ttss: this.$store.state.seaching,
      // jiagemin: this.$store.state.jiagemin,
      // jiagemax: this.$store.state.jiagemax,
    };
  },
  created() {
    this.axios
      .get("/v_chazhaosp?spbh= ")
      .then((response) => {
        if (response) {
          this.goodimgs = response.data.data;
          console.log(this.goodimgs);
          this.$nextTick(() => {
            this.tongji;
          });
        }
      })
      .catch((error) => {});
  },
  methods: {
    tongji() {
      this.xxs = 0;
      this.uuuss = 0;
      for (var i = 0; i < this.goodimgs.length; i++) {
        if (
          this.goodimgs[i].spm
            .toUpperCase()
            .search(this.$store.state.seaching.toUpperCase()) > -1 &&
          (this.goodimgs[i].dj >= this.$store.state.jiagemin ||
            this.$store.state.jiagemin == "") &&
          (this.goodimgs[i].dj <= this.$store.state.jiagemax ||
            this.$store.state.jiagemax == "")
        ){this.xxs++;}
          
        if (
          (a.dj >= $store.state.jiagemin || this.$store.state.jiagemin == "") &&
          (a.dj <= $store.state.jiagemax || this.$store.state.jiagemax == "")
        ){
          this.uuuss++;
        }
          
      }
      if (this.$store.state.seaching == "" && this.uuuss > 0) this.xxs = 1;
      // console.log(this.xxs);
      // console.log(this.$store.state.jiagemax)
    },
  },
  mounted() {
    // this.tongji();
    setInterval(this.tongji, 100);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.goods {
  width: 67%;
  text-align: center;
  overflow: hidden;
  margin: 0 auto;
  height: 100%;
  /* margin-left: 250px; */
}
.goods ul {
  flex-wrap: wrap;
}
.goods li {
  float: left;
  border: solid 2px #6dcff6;
  height: 380px;
  width: 30%;
  border-radius: 35px;
  margin-left: 30px;
  margin-top: 12px;
  margin-bottom: 20px;
  box-shadow: darkgrey 10px 10px 10px 1px;
  overflow: hidden;
}

.goods li:hover {
  box-shadow: darkgrey 10px 10px 8px 3px;
}

.goods a {
  color: #6dcff6;
  font-weight: 900;
  margin-bottom: 15px;
}
.goods a:hover {
  color: rgb(69, 180, 245);
  list-style: none;
  text-decoration: none;
}
.goods h1 {
  margin-top: 10px;
}

.goods img {
  height: 145px;
  width: 100%;
  transition: 1.5s;
}

.goods img:hover {
  transform: scale(1.2, 1.2);
}
.hhgoods {
  font-weight: 500;
  font-size: 20px;
}
</style>

