<template>
<div>
  <div class="thehhh">
    <span @click="loging()" id="denl"><a>登录</a></span>
    <span>|</span>
    <span @click="reg()" id="zc"><a>注册</a></span>
  </div>
  

  <div class="login">
       <h2>欢迎登录</h2>
       <div class="login_box">
          <input type="text" required="required" v-model="name">
          <label>用户名</label>
       </div>
       <div class="login_box">
        <input type="password" required="required" v-model="psw">
        <label>密码</label>
       </div>
       <a @click="login()" href="#">登录
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </a>
    </div>
</div>


<div class="floor">
        <span style="flex: 1;"><a target="_blank" href="https://beian.miit.gov.cn">
                <p style="color: aliceblue;font-weight: 900;text-decoration: none;float: right;">浙ICP备2021019852号-3</p>
            </a>
        </span><img src="../../static/images/备案图标.png"
            style="margin-top: 17px;margin-right: 5px;margin-left: 5px;height: 20px;width: 20px;">
        <span style="flex: 1;">
            <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33032202000203">
                <p style="color: aliceblue;font-weight: 900;text-decoration: none;float: left;">浙公网安备33032202000203号
                </p>
            </a></span>
    </div>
  
</template>

<script>
export default {
    data(){
        return{
           name:"" ,
           psw:"",

        }       
    },
    methods:{
        login(){
        this.axios
        .get(
          "/denglu?name=" +
            this.name+
            "&psd="+this.psw
        )
        .then((response) => {
          if (response.data.code==1) {
             this.$router.push('manage');
             this.$store.state.yhbh=response.data.data[0].gkbh;
             console.log(response.data.data[0].gkbh);
             this.$store.state.yhm=this.name;
          }else if (response.data.code==2) {
             this.$router.push('home');
             this.$store.state.yhbh=response.data.data[0].gkbh;
             this.$store.state.yhm=this.name;
             console.log(response.data.data[0].gkbh);
             console.log(response.data.data[0].gkbh+"yzy");
          }
          else{
            alert("登录失败")
          }
        })
        .catch((error) => {
            alert("登录失败")
        });
        },
        loging(){
          this.$router.push('login');
        },
        reg(){
          this.$router.push('registered');
        }
    }
    
}
</script>

<style scoped>
/* *{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,body{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    height: 100%;
    width: 100%;
} */

.floor {
  position: absolute;
  left: 50%;
  bottom: 5%;
    text-align: center;
    flex: 0;
    display: flex;
    flex-direction: row;
    transform: translate(-50%,-50%);
    width: 100%;
    z-index: 999;
}

.floor p{
  color: rgb(45, 186, 252) !important;
  margin-top: 15px;
}


.thehhh{
  position: absolute;
  left: 50%;
  top: 20%;
  width: 200px;
  height: 50px;
  text-align:center;
  margin-bottom: 10px;
  transform: translate(-50%,-50%);
  font-weight:900;
  font-size: 20px;
  
}

.thehhh span{
   margin-left:5px ;
}

.thehhh #denl{
color: rgb(45, 186, 252) !important;
}
.thehhh #zc{
  color: rgb(139, 136, 136) !important;
}

.login{
    /* margin-top: 10%;
    margin-left: 37%; */
    /* margin: 10% 15%; */
  /*弹性布局 让子元素称为弹性项目*/
   position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);

    display: flex;
    /*让弹性项目垂直排列，原理是改变弹性盒子的主轴方向*/
    flex-direction: column;
    align-items: center;
    width: 400px;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4);
    z-index: 50;
    position: fixed;
    border-radius: 35px;
    background: #dfdcdcaa;
}
.login h2{
  color: rgb(42, 145, 241);
  margin-bottom: 30px;
}
.login .login_box{
  position: relative;
  width: 100%;
}
.login .login_box input{
  outline: none;
  border: none;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 30px;
  color: rgb(32, 32, 32);
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #fff;
  background-color: transparent;
}
.login .login_box label{
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  color: rgb(45, 186, 252);
  pointer-events: none;
  transition: all 0.5s;
  font-weight: 900;
}
.login .login_box input:focus+label,
.login .login_box input:valid+label{
  top: -20px;
  color: #03e9f4;
  font-size:12px ;
}
.login a{
  overflow: hidden;
  position: relative;
  padding: 10px 20px;
  color: #03e9f4;
  text-decoration: none;
  font-weight: 500;
}
.login a:hover{
  color: #fff;
  border-radius:5px ;
  background-color: #03e9f4;
  box-shadow: 0 0 5px #03e9f4,0 0 25px #03e9f4,
  0 0 50px #03e9f4,0 0 100px #03e9f4;
}
.login a span{
  position: absolute;
}
.login a span:first-child{
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right,transparent,#03e9f4);
  animation: move1 1s linear infinite;
}
.login a span:nth-child(2){
  right: 0;
  top: -100%;
  width: 2px;
  height: 100%;
  background: linear-gradient(transparent,#03e9f4);
  animation: move2 1s linear 0.25s infinite;
}
.login a span:nth-child(3){
  right: -100%;
  bottom:  0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left,transparent,#03e9f4);
  animation: move3 1s linear 0.5s infinite;
}
.login a span:last-child{
  left: 0;
  bottom: -100%;
  width: 2px;
  height: 100%;
  background: linear-gradient(#03e9f4,transparent);
  animation: move4 1s linear 0.75s infinite;
}
@keyframes move1{
  0%{
    left: -100%;
  }
  50%,
  100%{
    left: 100%;
  }
}
@keyframes move2{
  0%{
    top: -100%;
  }
  50%,
  100%{
    top: 100%;
  }
}
@keyframes move3{
  0%{
    right: -100%;
  }
  50%,
  100%{
    right: 100%;
  }
}
@keyframes move4{
  0%{
    bottom: -100%;
  }
  50%,
  100%{
    bottom: 100%;
  }
}


</style>