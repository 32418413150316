<template>
    <el-aside width="200px" id="yzyaside">
      <el-scrollbar >
        <el-menu :default-openeds="['1', '3']">
          <el-menu-item index="1" id="elm-menu1" ><img class="toppng" src="/../../static/images/wentian.png" title="问天阁" /></el-menu-item>
          <el-menu-item index="1" @click="zhuye()">
            <template #title>
              <el-icon><i class="iconfont icon-shouye" /></el-icon>主页
            </template>
          </el-menu-item>
          <el-menu-item index="2" @click="manageuser()">
            <template #title>
              <el-icon
                ><i
                  class="
                    iconfont
                    icon-wenjianleixing-biaozhuntu-wenjianjia
                  " /></el-icon
              >用户管理
            </template>
          </el-menu-item>
          <el-sub-menu index="3" >
            <template #title>
              <el-icon
                ><i
                  class="
                    iconfont
                    icon-jingxiangcangku
                  " /></el-icon
              >商品管理
            </template>
              <el-menu-item index="3-1" @click="manage_commodity()">商品数据</el-menu-item>
              <el-menu-item index="3-2" @click="manage_commoditytp()">商品图片</el-menu-item>
          </el-sub-menu>
          <el-menu-item index="4">
            <template #title>
              <el-icon><i class="iconfont icon-youxiyouxiji" /></el-icon
              ><a
                href="https://www.226yzy.com"
                style="text-decoration: none; color: #000"
                >制作人员</a
              >
            </template>
          </el-menu-item>
        </el-menu>
      </el-scrollbar>
    </el-aside>
</template>

<script>
import { Menu as IconMenu, Message, Setting } from "@element-plus/icons-vue";


export default {
    data(){
        return{

        }
    },
    created() {
        // this.zhuye();
        // this.manageuser();
    },
    methods:{
      zhuye(){
        this.$router.push('/manage');
      },
      manageuser(){
        this.$router.push('userdata');
      },
      manage_commodity(){
        this.$router.push('commodity_data');
      },
      manage_commoditytp(){
        this.$router.push('sptp_data');
      }
      
    }
}
</script>

<style>
.layout-container-demo .el-aside {
  color: var(--el-text-color-primary);
  background: rgba(224, 221, 221, 0.288);
}

#yzyaside{
  background-color: rgb(206, 209, 212);
}

#yzyaside #elm-menu1{
  height: 100px;
}
.el-menu-item{
  background-color: rgb(206, 209, 212);
}
.el-sub-menu{
  background-color: rgb(206, 209, 212);
}

</style>