<template>
  <td>
    <button v-on:click="reduce()" v-bind:disabled="values <= 1">-</button
    ><label>&nbsp;{{ values }}&nbsp;</label
    ><button @click="add()">+</button>
  </td>
</template>

<script>
export default {
  data() {
    return {
      values: this.thesl,
      nnn: this.goodshh,
    };
  },
  props: ["thesl", "goodshh", "xx"],
  methods: {
    add: function () {
      this.axios
        .get("/Shoppingcart?cmd=increaseone&spbh=" + this.nnn + "&gkbh=226")
        .then((response) => {
          if (response) {
            this.values++;
            this.$emit('listenToChildEvent',this.values,this.xx);
          }
        })
        .catch((error) => {});
        
    },
    reduce: function () {
      if (this.values > 1) {
        this.axios
        .get("/Shoppingcart?cmd=decreaseone&spbh=" + this.nnn + "&gkbh=226")
        .then((response) => {
          if (response) {
            this.values--;
            this.$emit('listenToChildEvent',this.values,this.xx);    
          }
        })
        .catch((error) => {});
      }   
    },
  },
};
</script>

<style>
button{
  width: 25px;
  height: 30px;
  border-radius: 5px;
}
</style>