<template>
  <div class="footer1">
    <li>制作人:叶振宇&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;班级:计算2002</li>

    <li class="floorls">
        <span style="flex: 1;"><a target="_blank" href="https://beian.miit.gov.cn">
                <p style="color: aliceblue;font-weight: 900;text-decoration: none;float: right;">浙ICP备2021019852号-3</p>
            </a>
        </span><img src="../../../static/images/备案图标.png"
            style="margin-top: 17px;margin-right: 5px;margin-left: 5px;height: 20px;width: 20px;">
        <span style="flex: 1;">
            <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33032202000203">
                <p style="color: aliceblue;font-weight: 900;text-decoration: none;float: left;">浙公网安备33032202000203号
                </p>
            </a></span>
    </li>
  </div>
  
</template>

<script>
export default {
  name: "footer1",
  data() {
    return {};
  },
};
</script>

<style scoped>
/* 底部栏 */
.footer1 {
  background: linear-gradient(to right, #0e5eb9, #8ecaf1);
  height: 100px;
  width: 100%;
  flex: 0;
}
.footer1 li {
  text-align: center;
  margin: 10px;
  font-weight: 900;
  color: rgb(255, 255, 255);
}

.floorls {
  position:relative;
  left: 50%;
  bottom: 3%;
    text-align: center;
    flex: 0;
    display: flex;
    flex-direction: row;
    transform: translate(-50%,-50%);
    width: 100%;
    z-index: 999;
}

.floorls p{
  margin-top: 15px;
}
</style>