<template>
  <el-container id="container"> 
    <myaside />
      <el-container>
    <el-header class="headerMenu"><p>后台管理</p></el-header>
    <el-container class="layout-container-demo" >
      
      <el-container>
        <router-view />
        <!-- <maindata /> -->
        
      </el-container>
      
    </el-container>
    <el-footer>
      <div class="myfloor">
        <span style="flex: 1;"><a target="_blank" href="https://beian.miit.gov.cn">
                <p style="color: aliceblue;font-weight: 900;text-decoration: none;float: right;">浙ICP备2021019852号-3</p>
            </a>
        </span><img src="../../static/images/备案图标.png"
            style="margin-top: 17px;margin-right: 5px;margin-left: 5px;height: 20px;width: 20px;">
        <span style="flex: 1;">
            <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33032202000203">
                <p style="color: aliceblue;font-weight: 900;text-decoration: none;float: left;">浙公网安备33032202000203号
                </p>
            </a></span>
    </div>
    </el-footer>
    </el-container>
  </el-container>
</template>

<script >
import { Menu as IconMenu, Message, Setting } from "@element-plus/icons-vue";
import { ElLoading } from 'element-plus';
import { ElMessage, ElMessageBox } from 'element-plus';
import maindata from '../components/userdata';
import myaside from '../components/aside'
import zhuye from '../views/temp'

export default {
  components: {
    maindata,
    myaside,
    zhuye
  },
    data() {
      return {
        tableData: [],
        search: '',
        multipleSelection:[],
        dialogFormVisible:false,
        form:{name:'',psd:''},
        formLabelWidth : '140px'
      }
    },
    created() {
      this.hhh();
    },
    methods: {
      hhh(){
        document.getElementById("app").style.flexDirection="none";
      }
    },
    
}
</script>

<style scoped>
.headerMenu {
  font-weight: bold;
  color: dodgerblue;
  font-size: 15px;
  border-bottom: 1px solid #cfd3d5;
  height: 50px;
  text-align:left;
} 

.headerMenu p{
  margin-left: 30px;
  margin-top: 15px;
}

.layout-container-demo{
  height: 5%;
}
#container{
  flex-direction:none !important;
}
#container button{
  width: 50px !important;
}

.myfloor {
  position: absolute;
  left: 50%;
  bottom: 0%;
    text-align: center;
    flex: 0;
    display: flex;
    flex-direction: row;
    transform: translate(-50%,-50%);
    width: 90%;
    z-index: 999;
    z-index: 0;
    
}

.myfloor p{
  color: rgb(45, 186, 252) !important;
  margin-top: 15px;
}

el-footer{
  background-color: #35b6f6;
}

</style>
