<template>
  <div class="homegood" id="homegood">
    <div class="help" id="help">
      <ul>
        <li>
          <a href="#maintop">
            <p>精选航模</p>
          </a>
        </li>
        <li>
          <a href="#maintop2">
            <p>更多航模</p>
          </a>
        </li>
        <li>
          <a href="#maintop3">
            <p>我们的服务</p>
          </a>
        </li>
      </ul>
    </div>
    <div class="maintop">
      <a name="maintop"
        ><img id="title" src="../../static/images/精选航模.png"
      /></a>
    </div>
    <maingoods />
    <a name="maintop2"
      ><div class="maintop2">
        <h1>更多航模</h1>
      </div></a
    >
    <goods id="honmegoods" />
    <div class="maintop3">
      <a name="maintop3">
        <h1>我们的服务</h1>
      </a>
    </div>
    <storethings />
  </div>
</template>

<script>
import goods from "../components/module/goods.vue";
import header1 from "../components/fixed/header1";
import maingoods from "../components/module/maingoods.vue";
import storethings from "../components/module/storethings.vue";

export default {
  name: "home",
  components: {
    goods,
    header1,
    maingoods,
    storethings,
  },
  data() {
    return {
      seaching: "",
    };
  },
  created() {
    this.$nextTick(() => {
      var fff = setInterval(this.goto1, 100);
    });
  },
  methods: {
    goto1() {
      var helps = document.getElementById("help").getElementsByTagName("li");
      var helpsp = document.getElementById("help").getElementsByTagName("p");
      var hlepssettop = document.getElementById("help").offsetTop;
      var maintop1 = document.getElementsByClassName("maintop")[0].offsetTop;
      var maintop2 = document.getElementsByClassName("maintop2")[0].offsetTop;
      var maintop3 = document.getElementsByClassName("maintop3")[0].offsetTop;
      var f1 = document.documentElement.scrollTop;
      // console.log(maintop1);
      // console.log(maintop2);
      // console.log(maintop3);
      if (f1 >= 0 && f1 < maintop2 - 200) {
        helps[0].style.backgroundColor = "rgb(1, 162, 236)";
        helps[1].style.backgroundColor = "";
        helps[2].style.backgroundColor = "";

        helps[0].style.boxShadow = "rgb(68, 170, 218) 0px 0px 30px 5px";
        helps[1].style.boxShadow = "";
        helps[2].style.boxShadow = "";

        helpsp[0].style.color = "rgb(253, 251, 251)";
        helpsp[1].style.color = "";
        helpsp[2].style.color = "";
      } else if (f1 > maintop2 - 200 && f1 < maintop3 - 300) {
        helps[1].style.backgroundColor = "rgb(1, 162, 236)";
        helps[2].style.backgroundColor = "";
        helps[0].style.backgroundColor = "";

        helps[1].style.boxShadow = "rgb(68, 170, 218) 0px 0px 30px 5px";
        helps[2].style.boxShadow = "";
        helps[0].style.boxShadow = "";

        helpsp[1].style.color = "rgb(253, 251, 251)";
        helpsp[2].style.color = "";
        helpsp[0].style.color = "";
      } else if (f1 > maintop3 - 300) {
        helps[2].style.backgroundColor = "rgb(1, 162, 236)";
        helps[1].style.backgroundColor = "";
        helps[0].style.backgroundColor = "";

        helps[2].style.boxShadow = "rgb(68, 170, 218) 0px 0px 30px 5px";
        helps[1].style.boxShadow = "";
        helps[0].style.boxShadow = "";

        helpsp[2].style.color = "rgb(253, 251, 251)";
        helpsp[1].style.color = "";
        helpsp[0].style.color = "";
      }
    },
  },
};
</script>
  

<style>
.homegood {
  margin: 0px auto;
  width: 100%;
  padding-top: 85px;
  flex: 1;
}
#homegoods {
  margin: 0 auto;
  width: 100%;
}
.help {
  position: fixed;
  z-index: 3;
  top: 100px;
  left: 25px;
  width: 150px;
  height: 250px;
  box-shadow: darkgrey 10px 10px 30px 5px;
  border-radius: 30px;
  color: rgb(97, 96, 96);
}
.help ul {
  margin-top: 20px;
  text-align: center;
}
.help li p {
  padding: 16px;
  color: rgb(122, 121, 121);
}
.help li {
  margin-top: 10px;
  border-radius: 5px;
  /* background: #0e5eb9; */
  width: 100%;
  height: 60px;
  font-weight: 900;
}
.help li p:hover {
  background-color: rgb(1, 162, 236);
  box-shadow: rgb(68, 170, 218) 0px 0px 30px 5px;
  color: rgb(253, 251, 251);
  border-radius: 5px;
}
.maintop {
  width: 100%;
  height: 200px;
  text-align: center;
}

.maintop2 {
  /* margin-top: 10px; */
  width: 100%;
  height: 50px;
  text-align: center;
  font-weight: 800;
}

.maintop3 {
  /* margin-top: 100px; */
  width: 100%;
  height: 50px;
  text-align: center;
  font-weight: 800;
}

#title {
  position: absolute;
  left: 35%;
  width: 30%;
  height: 115px;
  margin: 50px;
}
</style>