<template>
  <div class="homegood" id="homegood">
    <goods id="honmegoods" />
  </div>
</template>

<script>
import goods from "../components/module/goods.vue";
import header1 from "../components/fixed/header1";

export default {
  name: "home",
  components: {
    goods,
    header1,
  },
  data() {
    return {
      seaching: "",
    };
  },
};
</script>

<style>
.homegood {
  margin: 0px auto;
  width: 100%;
  padding-top: 85px;
  flex: 1;
}
#homegoods {
  margin: 0 auto;
  width: 100%;
}
</style>