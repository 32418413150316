<template>
  <div class="thetemp">

  </div>
</template>

<script>
export default {
    methods:{
      goto1(){
       this.$router.push('/shoppingTrolley')
       }
    },
    created() {
    this.$nextTick(() => {
        this.goto1();
    });
  },
}
</script>

<style>
.thetemp{
    height: 100%;
}
</style>