<template>
  <div id="store">
    <header1 />
    <!-- <login v-if="$store.state.yhbh==''"/>
    <div class="menban" v-if="$store.state.yhbh==''"></div> -->
       <router-view />
    <backtop />
    <footer1 />
  </div>
</template>

<script>
import footer1 from "../components/fixed/footer1.vue";
import header1 from "../components/fixed/header1";
import backtop from "../components/fixed/backtop.vue";


export default {
  name: "App",
  data() {
    return {
      // mall_info:[]
    };
  },
  created() {
    // this.mainloading();
    this.hhh2();
  },
  components: {
    footer1,
    header1,
    backtop,
  },
  methods:{
    mainloading(){
      this.$router.push('main');
    },
    hhh2(){
        document.getElementById("app").style.flexDirection="column";
    }
  }
};
</script>

<style>


* {
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}
html,
body {
  overflow-x: hidden;
  cursor: url(/static/coursor/guangbiao.ico),auto;
  
}

#store{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
}



/* 悬停图片时的鼠标指针 */
/* img {
  cursor: url('/static/coursor/Comix_White_Link.cur'), auto !important;
} */

/* 选择链接标签时的鼠标指针 */
a:hover {
  cursor: url('/static/coursor/Comix_White_Link.cur'), auto;
}

/* 悬停按钮时的鼠标指针 */
button:hover {
  cursor: url('/static/coursor/Comix_White_Link.cur'), auto;
}

/* 悬停列表标签时的鼠标指针 */
i:hover {
  cursor: url('/static/coursor/Comix_White_Link.cur'), auto;
}

/* 滚动条 */
::-webkit-scrollbar-thumb {
  background-color: #2ab1ff;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.4) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.4) 75%,
    transparent 75%,
    transparent
  );
  border-radius: 3em;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 3em;
}

::-webkit-scrollbar {
  width: 8px;
  height: 15px;
}






</style>
