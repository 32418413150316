<template>
  <div>
      <span class="jiages">
         最低价：<input id="jiage" placeholder="请输入数字" oninput="value=value.replace(/[^\d]/g,'')" type="text" v-model="jiagemin"> 
      </span>      
      <span class="jiages">
        最高价：<input id="jiage" placeholder="请输入数字" oninput="value=value.replace(/[^\d]/g,'')" type="text" v-model="jiagemax">  
      </span>
      
  </div>
</template>

<script>
export default {
    data(){
        return{
            jiagemin:this.$store.state.jiagemin,
            jiagemax:this.$store.state.jiagemax,
        }
    },
    watch: {
    jiagemin: function (val) {
      this.$store.commit("jiagemin", val);
    },
    jiagemax: function (val) {
      this.$store.commit("jiagemax", val);
    },
  },

}
</script>

<style>
.jiages{
    margin: 10px;
    color: deepskyblue;
    font-weight: 800;
}
#jiage{
    margin: 5px;
    border-radius: 30px;
    /* border-color:rgb(7, 118, 192) ; */
    border: solid 2px #6dcff6;
}
</style>