import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/loginView.vue'
import DemoView from '../views/manageView.vue'
// import homeView from '../views/home.vue'
import storeView from '../views/storeView.vue'
import maindata from '../components/userdata.vue';
import commodity_data from '../components/commodity_data.vue';
import sptp_data from '../components/sptp_data.vue';
import maindatas from '../components/maindata.vue';


import tempsa from '../components/module/temp.vue'
import tempss from '../views/temp.vue';
import homes from '../views/home.vue';

import allgoods from '../views/allgoods.vue';
import shoppingTrolley from '../views/shoppingTrolley.vue';
import temp from '../views/temp.vue';
import seachgoods from '../views/seachgoods.vue';
import commodityDetails from '../views/commodityDetails.vue';
import dingdan from '../views/dingdan.vue';
import registeredView from '../views/registeredView.vue';
import NotFound from '../views/404.vue'


import { useRouter } from 'vue-router';


const routes = [
  {
    path: '/',
    name: '登录',
    component: LoginView,
    meta: {title:'问天阁航模商城'},
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '/login',
    name: '登录 ',
    component: LoginView,
    meta: {title:'登录'},
  },
  {
    path: '/registered',
    name: '注册',
    component: registeredView,
    meta: {title:'注册'},
  },
  
  {
    path: '/manage',
    name: '后台管理',
    component: DemoView,
    meta: {title:'后台管理主页'},
    children: [
      {
        path:"",
        name: '管理主页',
        component: maindata,
        meta: {title:'后台管理主页-数据概览'},
      },
      {
        path:"/userdata",
        name: '用户数据',
        component: maindata,
        meta: {title:'后台管理主页-用户数据'},
      },
      {
        path:"/commodity_data",
        name: '商品数据',
        component: commodity_data,
        meta: {title:'后台管理主页-商品数据'},
      },
      {
        path:"/sptp_data",
        name: '商品图片',
        component: sptp_data,
        meta: {title:'后台管理主页-商品图片'},
      },

    ]
  },
  {
    path: '/home',
    name: '用户主页',
    redirect:"/home",
    component: storeView,
    meta: {title:'用户主页'},
    children: [
      {
        path: '',
        component: homes,
        name: '用户主页',
        meta: {title:'用户主页'},
      },
      {
        path: '/allgoods',
        component: allgoods,
        name: '全部商品',
        meta: {title:'用户主页-全部商品'},
      },
      {
        path: '/shoppingTrolley',
        component: shoppingTrolley,
        name: '购物车',
        meta: {title:'用户主页-购物车'},
      },
      {
        path: '/temp',
        component: tempsa,
        name: '请等待',
        meta: {title:'用户主页-请等待'},
      },
      {
        path: '/seachgoods',
        component: seachgoods,
        name: '商品查找',
        meta: {title:'用户主页-商品查找'},
      },
      {
        path: '/commodityDetails',
        component: commodityDetails,
        name: '商品详情',
        meta: {title:'用户主页-详情'},
      },
      {
        path: '/commodityDetails/:sss',
        component: commodityDetails,
        name: '商品详情1',
        meta: {title:'用户主页-商品详情'},
      },
      {
        path: '/dingdan',
        component: dingdan,
        name: '订单',
        meta: {title:'用户主页-订单'},
      },

    ]
  },
]


const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
})


export default router
