<template>
  <div id="backtop" @click="gobacktop">
    <a><img src="../../../static/images/backtop.png" title="返回顶部"/></a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      thescroll:0,
      varflag:0
    };
  },
  methods: {
    gobacktop() {
      var play = setInterval(function () {
        if (
          document.body.scrollTop > 0 ||
          document.documentElement.scrollTop > 0
        ) {
          document.body.scrollTop -= 10;
          document.documentElement.scrollTop -= 10;
        } else clearInterval(play);
      }, 1);
    },
    
  },
  created() {
    this.$nextTick(function () {
      
    });
  },
  watch: {

  }
};
</script>

<style>
#backtop {
  /* display:none; */
  position: fixed;
  z-index: 999;
  right: 3%;
  bottom: 0px;
  width: 100px;
  height: 100px;
}
</style>