<template>
  <div class="box">
    <form id="search">
      <input id="searchs" placeholder="Search" v-model="seaching" v-bind:onkeyup="goto()"/>
      <div id="ss"></div>
    </form>
  </div>
</template>

<script>
export default {
  name: "mysearch",
  data() {
    return {
      seaching: this.$store.state.seaching,
    };
  },
  methods:{
    goto(){
      if(this.seaching)
       this.$router.push('/seachgoods')
    }
  },
   watch: {
    seaching: function (val) {
      this.$store.commit("seaching", val);
    },
  },
};
</script>

<style>
.box {
  padding: 9px;
  position: absolute;
  left: 69%;
  width: 20%;
  height: 50px;
  z-index: 3;
}

#search input {
  z-index: 5;
  outline: none;
  height: 35px;
  background: #ededed77 url(../../../static/images/search-icon.png) no-repeat
    11px center;
  border: solid 2px #6dcff6;
  padding: 0px 15px 0px 0px;
  border-radius: 35px;
  width: 200px;
  padding-left: 35px;
  font-weight: 800;
  color: rgb(0, 171, 238);
}
#ss {
  width: 174px;
  border: solid#6dcff6 2px;
  border-top: 0;
  display: none;
  margin-left: 12px;
  font-weight: 800;
}
</style>