<template>
  <div class="main" id="main">
    <template v-for="(a, x) in maingoodsc">
    <ul id="goods1" v-if="x<3">
      
      <li  id="good1" v-if="x % 2 == 0 && x < 4">
        <router-link :to="'/commodityDetails/' + a.spbh">
          <img id="goodimg1" v-bind:src="a.tplj"
        /></router-link>
      </li>
      <li id="good2" v-if="x % 2 == 0&& x < 3">
        <router-link :to="'/commodityDetails/' + a.spbh">
          <h1>{{ a.spm }}</h1>
        </router-link>
        <br />
        <h4 v-html="a.spms"></h4>
        <br /><br />
        <p class="p1">￥&nbsp{{ (a.dj).toFixed(2) }}</p>
        <router-link class="good" :to="'/commodityDetails/' + a.spbh">
          <p>详情</p>
        </router-link>
      </li>

      <li id="good4" v-if="x % 2 == 1 && x < 3">
        <router-link :to="'/commodityDetails/' + a.spbh">
          <h1>{{ a.spm }}</h1> </router-link
        ><br />
        <h4 v-html="a.spms"></h4>
        <br /><br />
        <p class="p1">￥&nbsp{{(a.dj).toFixed(2) }}</p>
        <router-link class="good" :to="'/commodityDetails/' + a.spbh">
          <p>详情</p>
        </router-link>
      </li>
      <li  id="good3" v-if="x % 2 == 1&& x < 3">
        <router-link :to="'/commodityDetails/' + a.spbh"
          ><img id="goodimg2" v-bind:src="a.tplj"
        /></router-link>
      </li>
    </ul>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      maingoodsc: [],
    };
  },
  created() {
    this.axios
      .get("/v_chazhaosp?spbh= ")
      .then((response) => {
        if (response) {
          this.maingoodsc = response.data.data;
          console.log(this.maingoodsc);
        }
      })
      .catch((error) => {});
  },
  methods: {},
};
</script>

<style>
.main li {
  width: 30%;
  height: 60%;
  float: none;
}
.main {
  width: 100%;
  height: 1100px;
}


.main img {
  transition: 1.5s;
}

.main img:hover {
  transform: scale(1.2, 1.2);
}
.main h1 {
  color: #000;
  font-weight: 900;
}
.main h4 {
  color: #6dcff6;
  font-weight: 800;
}

.p1 {
  font-weight: 900;
  font-size: xx-large;
  text-align: left;
  float: left;
  margin-left: 17%;
}

/* .p2 {
  font-size: x-large;
  color: darkgray;
  text-decoration: line-through;
  float: left;
  margin-left: 3%;
  margin-top: 1.5%;
} */

.good {
  background: linear-gradient(to right, #0e5eb9, #8ecaf1);
  width: 75px;
  height: 50px;
  border-radius: 35px;
  left: 290px;
  float: left;
  margin-left: 15%;
  z-index: 5;
}
.good:hover {
  box-shadow: rgb(111, 202, 238) 1px 1px 30px 1px;
}

.good p {
  margin-top: 10px;
  font-weight: 900;
  color: rgb(250, 242, 242);
  font-size: larger;
}
#goods1 {
  width: 63%;
  height: 300px;
  position: relative;
  left: 19%;
  border: solid 2px #6dcff6;
  border-radius: 35px;
  box-shadow: darkgrey 10px 10px 5px 5px;
  margin-top: 50px;
  margin-bottom:50px ;
}

#good1 {
  position: absolute;
  width: 50%;
  height: 100%;
  border-bottom-left-radius: 35px;
  border-top-left-radius: 35px;
  overflow: hidden;
}

#good1 img {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 35px;
  border-top-left-radius: 35px;
}

#good2 {
  position: absolute;
  width: 50%;
  margin-top: 10px;
  right: 0;
  text-align: center;
}

#good4 {
  position: absolute;
  width: 50%;
  margin-top: 10px;
  left: 0;
  text-align: center;
}


#goods1:hover {
  box-shadow: darkgrey 1px 10px 15px 5px;
}

#good3 {
  position: absolute;
  width: 50%;
  height: 100%;
  border-bottom-right-radius: 35px;
  border-top-right-radius: 35px;
  right: 0;
  overflow: hidden;
}

#good3 img {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 35px;
  border-top-right-radius: 35px;
  right: 0%;
}



</style>