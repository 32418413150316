<template>
  <router-view ></router-view>
</template>


<script>


export default {
  name: "App",
  data() {
    return {
      // mall_info:[]
    };
  },
  components: {

  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;

height: 100%;
width: 100%;
  
  display: flex;
  // flex-direction: column;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
}

button.el-button {
    width: 50px !important;
}

nav {
  // padding: 30px;

  // a {
  //   font-weight: bold;
  //   color: #2c3e50;

  //   &.router-link-exact-active {
  //     color: #42b983;
  //   }
  // }
}





* {
  list-style: none;
  text-decoration: none;
}
html,
body {
  overflow-x: hidden;
  margin: 0;
  height: 100%;
  // cursor: url(/static/coursor/guangbiao.ico),auto;
}

/* 滚动条 */
::-webkit-scrollbar-thumb {
  background-color: #2ab1ff;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.4) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.4) 75%,
    transparent 75%,
    transparent
  );
  border-radius: 3em;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 3em;
}

::-webkit-scrollbar {
  width: 8px;
  height: 15px;
}

</style>
