<template>
  <div id="showgoods">
    <div class="help" id="help">
      <ul>
        <li>
          <a href="#" @click="helps0">
            <p>商品信息</p>
          </a>
        </li>
        <li>
          <a href="#" @click="helps1">
            <p>商品价格</p>
          </a>
        </li>
        <li>
          <a href="#" @click="helps2">
            <p>商品评价</p>
          </a>
        </li>
      </ul>
    </div>
    <div class="mains" id="mains">
      <h1>{{ this.thegooodsthings.spm }}</h1>
      <div class="mainthings" id="mainthings">
        <p v-html="hhhgoods"></p>
      </div>
      <li class="uuu"><img id="theimg" v-bind:src="this.goodsimgs" /></li>
      <div class="goumai">
        购买数量：
        <button @click="reduce()" v-bind:disabled="values <= 1">-</button
        ><label>&nbsp;{{ values }}&nbsp;</label
        ><button @click="add()" v-bind:disabled="thegooodsthings.sl <= values">
          +
        </button>
      </div>
      <div class="cc1">
        <!-- <button class="buttonaddshop" @click="xiadan()">下订单</button> -->
        <button class="buttonaddshop" @click="addshopping()" id="addgwc">加入购物车</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodsthings",
  components: {},
  data() {
    return {
      goodsimgs: [],
      thegooodsthings: [],
      shopping: {},
      hhhgoods: "",
      updatas:{gkbh:'',spbh:'',sl:''},
      values: 1,
    };
  },
  created() {
    this.axios
      .get("/spjj?spbh=" + this.$route.params.sss)
      .then((response) => {
        console.log(this.$route.params.sss);
        
        if (response) {
          this.goodsimgs = response.data.data[0].tplj;
          console.log(this.goodsimgs)
        }
      })
      .catch((error) => {});
    this.axios
      .get("/chazhaospbh?spbh=" + this.$route.params.sss)
      .then((response) => {
        if (response) {
          this.thegooodsthings = response.data.data[0];
          this.$nextTick(function () {
            this.helps0();
          });
        }
      })
      .catch((error) => {});
  },
  computed: {},
  methods: {
    xiadan() {
      this.axios
        .get(
          "/CreateOrder?src=detail&gkbh=" +
            this.$store.state.yhbh +
            '&psfs=快递&xx=[{spbh:"' +
            this.thegooodsthings.spbh +
            '",sl=' +
            this.values +
            ",dj:" +
            (this.thegooodsthings.dj * this.thegooodsthings.zk).toFixed(2) +
            "}]"
        )
        .then((response) => {
          if (response.data == 0) {
            alert("下单成功");
          } else {
            alert("库存不足下单失败");
          }
        })
        .catch((error) => {
          alert("下单失败");
        });
    },
    addshopping: function () {
      this.updatas.gkbh=this.$store.state.yhbh;
      this.updatas.spbh=this.$route.params.sss;
      this.updatas.sl=this.values;
      this.axios
        .get(
          "/addgwc?spbh=" +
            this.$route.params.sss +
            "&gkbh=" +
            this.$store.state.yhbh +
            "&sl=" +
            this.values
        )
        .then((response) => {
          if (response) {
            alert("添加至购物车成功");
          }
        })
        .catch((error) => {
          alert("添加至购物车失败");
        });
    },
    add: function () {
      this.values++;
    },
    reduce: function () {
      if (this.values > 1) {
        this.values--;
      }
    },
    helps0() {
      var helps = document.getElementById("help").getElementsByTagName("li");
      var helpsp = document.getElementById("help").getElementsByTagName("p");
      this.hhhgoods =
        "<span class='shuxing'>商品名：</span>" +
        this.thegooodsthings.spm +
        "<br><span class='shuxing'>商品现价：</span>¥&nbsp;<span class='xianjia'>" +
        (this.thegooodsthings.dj * this.thegooodsthings.zk).toFixed(2) +
        "</span>" +
        "<br><span class='shuxing'>商品编号：</span>" +
        this.thegooodsthings.spbh +
        "<br><span class='shuxing'>商品类别：</span>" +
        this.thegooodsthings.splb +
        "<br><span class='shuxing'>上架日期：</span>" +
        this.thegooodsthings.sjrq +
        "<br><span class='shuxing'>生产日期：</span>" +
        this.thegooodsthings.scrq +
        "<br><span class='shuxing'>商品状态：</span>" +
        (this.thegooodsthings.spzt == 1
          ? "可售"
          : "<span class='jinggao'>不可售</span>");
      helps[0].style.backgroundColor = "rgb(1, 162, 236)";
      helps[1].style.backgroundColor = "";
      helps[2].style.backgroundColor = "";

      helps[0].style.boxShadow = "rgb(68, 170, 218) 0px 0px 30px 5px";
      helps[1].style.boxShadow = "";
      helps[2].style.boxShadow = "";

      helpsp[0].style.color = "rgb(253, 251, 251)";
      helpsp[1].style.color = "";
      helpsp[2].style.color = "";
    },
    helps1() {
      var helps = document.getElementById("help").getElementsByTagName("li");
      var helpsp = document.getElementById("help").getElementsByTagName("p");
      this.hhhgoods =
        "<span class='shuxing'>商品现价：</span>¥&nbsp;<span class='xianjia'>" +
        (this.thegooodsthings.dj * this.thegooodsthings.zk).toFixed(2) +
        "</span>" +
        "<br><span class='shuxing'>原价：</span>¥&nbsp;<span class='olddj'>" +
        this.thegooodsthings.dj +
        "</span>" +
        "<br><span class='shuxing'>商品折扣：</span><span class='thezk'>-" +
        (100 - this.thegooodsthings.zk * 100) +
        "%</span>" +
        "<br><span class='shuxing'>商品库存数量：</span><span class='" +
        (this.thegooodsthings.sl < 10 ? "kuchun1" : "kuchun2") +
        "'>" +
        this.thegooodsthings.sl +
        "</span>" +
        "<br><span class='shuxing'>商品状态：</span>" +
        (this.thegooodsthings.spzt == 1
          ? "可售"
          : "<span class='jinggao'>不可售</span>");
      helps[1].style.backgroundColor = "rgb(1, 162, 236)";
      helps[0].style.backgroundColor = "";
      helps[2].style.backgroundColor = "";

      helps[1].style.boxShadow = "rgb(68, 170, 218) 0px 0px 30px 5px";
      helps[0].style.boxShadow = "";
      helps[2].style.boxShadow = "";

      helpsp[1].style.color = "rgb(253, 251, 251)";
      helpsp[0].style.color = "";
      helpsp[2].style.color = "";
    },
    helps2() {
      var helps = document.getElementById("help").getElementsByTagName("li");
      var helpsp = document.getElementById("help").getElementsByTagName("p");
      this.hhhgoods =
        "<span class='shuxing'>商品评价：</span>" + this.thegooodsthings.spms;
      helps[2].style.backgroundColor = "rgb(1, 162, 236)";
      helps[1].style.backgroundColor = "";
      helps[0].style.backgroundColor = "";

      helps[2].style.boxShadow = "rgb(68, 170, 218) 0px 0px 30px 5px";
      helps[1].style.boxShadow = "";
      helps[0].style.boxShadow = "";

      helpsp[2].style.color = "rgb(253, 251, 251)";
      helpsp[1].style.color = "";
      helpsp[0].style.color = "";
    },
  },
};
</script>

<style >
#showgoods {
  margin: 0px auto;
  margin-top: 75px;
  width: 100%;
  height: 100%;
  flex: 1;
}
#addgwc{
  margin-left:60px ;
}
.buttonaddshop {
  background-color: rgb(241, 176, 56);
  border-radius: 5px;
  width: 150px;
  height: 50px;
  color: aliceblue;
  font-size: large;
  font-weight: 800;
  margin-top: 20px;
  text-decoration: none;
}
#uushop {
  text-decoration: none;
  color: aliceblue;
}

.help {
  position: fixed;
  z-index: 3;
  top: 100px;
  left: 25px;
  width: 150px;
  height: 250px;
  box-shadow: darkgrey 10px 10px 30px 5px;
  border-radius: 30px;
  color: rgb(97, 96, 96);
}
.help ul {
  margin-top: 20px;
  text-align: center;
}
.help li p {
  padding: 16px;
  color: rgb(122, 121, 121);
  font-weight: 900;
}
.help li {
  margin-top: 10px;
  border-radius: 5px;
  /* background: #0e5eb9; */
  width: 100%;
  height: 60px;
  font-weight: 900;
}
.help li p:hover {
  background-color: rgb(1, 162, 236);
  box-shadow: rgb(68, 170, 218) 0px 0px 30px 5px;
  color: rgb(253, 251, 251);
  border-radius: 5px;
}

.mains {
  width: 70%;
  height: 500px;
  float: left;
  margin-top: 20px;
  margin-left: 20%;
  border: solid 2px #6dcff6;
  box-shadow: darkgrey 10px 10px 10px 1px;
  border-radius: 35px;
  position: relative;
}
.mains h1 {
  text-align: center;
  font-weight: 900;
}
.mains img {
  position: absolute;
  width: 300px;
  height: 200px;
  border-radius: 35px;
  transition: 1.5s;
  left: 0%;
}
.mains img:hover {
  transform: scale(1.2, 1.2);
}
.mainthings {
  width: 70%;
  height: 300px;
  position: absolute;
  left: 5%;
  margin: 45px;
  font-size: x-large;
  text-align: left;
}
.mainthings p {
  color: rgb(54, 53, 53);
  font-weight: 900;
}
.uuu {
  overflow: hidden;
  position: absolute;
  right: 7%;
  width: 300px;
  height: 200px;
  border-radius: 35px;
}

.cc1 {
  position: absolute;
  bottom: 105px;
  left: 35%;
}

.shuxing {
  color: #1691f5;
}

.jinggao {
  color: red;
}
.olddj {
  color: rgb(122, 124, 124);
  text-decoration: line-through;
}
.xianjia {
  color: gold;
}
.kuchun1 {
  color: red;
}
.thezk {
  color: rgb(22, 243, 70);
}
.uuu {
  border: #59cbfd solid 2px;
}
.goumai {
  color: #1691f5;
  font-weight: 800;
}
</style>