import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './style/iconfont.css'
import { ElLoading } from 'element-plus';

import '../static/css/default.css'



createApp(App).use(store).use(router).use(VueAxios,axios).use(ElementPlus).mount('#app')

axios.defaults.baseURL = 'https://api.226yzy.com/'

import { useDark, useToggle } from '@vueuse/core'

const isDark = useDark()
const toggleDark = useToggle(isDark)

router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title;
    next();
});

router.afterEach((to, from)=>{
	window.scrollTo(0,0);	
});

