<template>
   <div class="things">
        <li>
            <img src="../../../static/images/assistant.png">
            <h1>产品服务</h1>
            <br><br>
            <p>最专业的服务<br>为您在线答疑解惑</p>
            <br><br>
            <a href="https://www.226yzy.com">了解更多</a>
        </li>
        <li>
            <img src="../../../static/images/service.png">
            <h1>配件介绍</h1>
            <br><br>
            <p>对配件有疑问？<br>在线百科为您解答</p>
            <br><br>
            <a href="https://www.226yzy.com">了解更多</a>
        </li>
        <li>
            <img src="../../../static/images/analytics.png">
            <h1>航模教学</h1>
            <br><br>
            <p>新手小白？<br>这里有最专业的入门教学</p>
            <br><br>
            <a href="https://www.226yzy.com">了解更多</a>
        </li>
    </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
}
</script>

<style>
.things{
    height: 500px;
    width: 63%;
    position: relative;
    left: 20%;
    margin-top: 5px;
    text-align: center;
    overflow: hidden;
}

/* 底部其他信息栏 */

.things li{
    float: left;
    border: solid 2px #6dcff6;
    height: 400PX;
    width: 29%;
    border-radius: 35px;
    margin-left: 30px;
    margin-top: 12px;
    box-shadow: darkgrey 10px 10px 20px 1px;
    overflow: hidden;
    margin-bottom: 50px;
    font-weight: 800;
}

.things li:hover{
    box-shadow: darkgrey 10px 10px 15px 3px;
}

.things a{
    color: #6dcff6;
    font-weight: 900;
}
.things a:hover{
    font-size: large;
}
</style>