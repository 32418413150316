<template>
  <div class="top1" id="toptop1">
    <div class="toptop">
      <router-link to="/home">
        <img class="toppng" v-bind:src="this.mall_info.dptb" title="问天阁" />
      </router-link>
      <ul id="homepage">
        <li><router-link to="/home">首页</router-link></li>
        <li><router-link to="/allgoods">全部商品</router-link></li>
        <li><router-link to="/shoppingTrolley">购物车</router-link></li>
        <li><router-link to="/dingdan">订单</router-link></li>
        <li><a href="https://www.226yzy.com">消息</a></li>
        <li><a href="https://www.226yzy.com">收藏</a></li>
        <li><a href="https://www.226yzy.com">客服</a></li>
        <li><a href="https://www.226yzy.com">关于我们</a></li>
      </ul>
    </div>
    <mysearch />
    <div class="user">
      <ul v-if="$store.state.yhbh==''">
        <span id="Login"><a href="https://www.226yzy.com">登录</a></span>
        <span>|</span>
        <span id="Register"><a href="https://www.226yzy.com">注册</a></span>
      </ul>
      <ul v-else id="helloyhm">
        欢迎登录：{{$store.state.yhm}}
      </ul>
    </div>
  </div>
</template>


<script>
import mysearch from "../module/myseach.vue";

export default {
  name: "top1",
  data() {
    return {
      mall_info: ''
    };
  },//{bh:'',dpm:'',dptb:'',dpgg:'',dpms:''},
  components: {
    mysearch,
  },
  methods: {
  },
  created() {
    
  },
  created: function () {
    this.axios
      .get("/MallInfo")
      .then((response) => {
        if (response) {
          this.mall_info = response.data.data[0];
          console.log(this.mall_info.dptb);
        }
      })
      .catch((error) => {});
  },
};
</script>



<style>
#helloyhm{
  color: #ffffff;
  font-weight: 800;
  margin-top: 15px;
}
.top1 {
  background: linear-gradient(to right, #0e5eb9, #3498db);
  height: 50px;
  width: 100%;
  position: fixed;
  z-index: 99;
  flex: 0;
}

.toptop {
  width: 50%;
  position: absolute;
  left: 20%;
  z-index: 3;
}

.toppng {
  height: 100px;
  height: 50px;
  float: left;
}

#homepage {
  padding: 10px;
  position: absolute;
  left: 25%;
  font-size: large;
  font-weight: 900;
}

.toptop ul li {
  float: left;
  list-style: none;
  margin-left: 20px;
  margin-top: 5px;
}

.toptop ul li a:hover {
  text-decoration: none;
  color: rgb(19, 81, 197);
}

.toptop ul li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.user {
  position: absolute;
  right: 25px;
}

.user span {
  text-decoration: none;
  color: #ffffff;
  float: left;
  margin: 2px;
  margin-top: 15px;
  font-weight: 900;
}

.user a {
  color: #ffffff;
}

.user a:hover {
  color: #0e5eb9;
}

.slogan {
  position: absolute;
  top: 20%;
  left: 70%;
  z-index: 0;
}

.slogan img {
  width: 200px;
}
</style>