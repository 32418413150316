<template>
  <div class="not-found">
    <img src="../assets/404.gif" alt="点击前往登录页面" title="点击前往登录页面" @click="gohome()"/>
  </div>
</template>
<script>
export default {
  name: '404',
  components: {},
  methods:{
      gohome(){
          this.$router.push('/')
      }
  }
}
</script>
<style scoped>
.not-found {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.not-found img {
  width: 100%;
  height: 100%;
}
</style>>