<template>
  <div class="home" id="home">
    <bannerbox />
    <div class="maintop1"><img src="/../static/images/嘉年华.png"></div>
    <div class="maintop"><img id="title" src="/../static/images/精选航模.png"></div>
    <maingoods/>
    <!-- <div id="thegoods1">
      <goods />
    </div>  -->
    <storethings/>
  </div>
</template>

<script>
import goods from "../components/module/goods.vue";
import header1 from "../components/fixed/header1";
import bannerbox from "../components/module/banner.vue";
import maingoods from '../components/module/maingoods.vue';
import storethings from '../components/module/storethings.vue';

export default {
  name: "home",
  components: {
    goods,
    header1,
    bannerbox,
    maingoods,
    storethings
  },
  data() {
    return {};
  },
};
</script>

<style>
.home {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding-top: 0px;
  flex: 1;
}
#thegoods1 {
  width: 100%;
}


/* 嘉年华宣传海报部分 */

.maintop1 {
    width: 100%;
    height: 700px;
}


.maintop1 img {
    /* position: absolute; */
    width: 100%;
    height: 700px;
}

.maintop {
    width: 100%;
    height: 200px;
}

/* 精选航模标题左侧装饰 */

/* #zs1 {
    width: 175px;
    height: 200px;
} */

/* 精选航模标题 */

#title {
    /* position: absolute; */
    left: 32%;
    width: 30%;
    height: 115px;
    margin-top: 50px;
}

</style>